'use client';
import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import styles from './tables.module.scss';
import first from "../../../config/first";
import second from "../../../config/second";
import categories from "../../../config/categories";
import {Link, useNavigate} from "react-router-dom";

const Page = () => {
    const location = useLocation();
    const { cards } = location.state || {};
    const router = useNavigate();
    const [tales, setTales] = React.useState<any[]>([]);
    const [filters, setFilters] = React.useState<any[]>([]);
    const [mode, setMode] = React.useState<string>('list')
    //const favorites = useSelector((state: { favorites: any; }) => state.favorites);

    useEffect(() => {
        document.title = "Таблицы"; // Устанавливаем заголовок
    }, []);

    const goBack = () => {
        if (mode === 'favorites') {
            setMode('list')
        } else {
            router(-1);
        }
    };

    const toggleFilter = (categoryId: any) => {
        setFilters(prev => {
            if (prev.includes(categoryId)) {
                // Если категория уже есть в фильтрах, удаляем её
                return prev.filter(id => id !== categoryId);
            } else {
                // Добавляем категорию в список фильтров
                return [...prev, categoryId];
            }
        });
        console.log(filters);
    };

    const loadFromFavorites = async () => {
        const rawStorage = await localStorage.getItem('@favorites');
        if (rawStorage) {
            const storage = JSON.parse(rawStorage);
            if (cards && storage[cards]) {
                // Определение массива данных на основе типа карточек
                const itemsArray = cards === 'first' ? first : second;

                // Фильтрация элементов, где id элемента приводится к строке для сравнения
                const filteredItems = itemsArray.filter(item => storage[cards].includes(item.id));

                console.log(filteredItems); // Логирование отфильтрованных элементов для отладки
                setTales(filteredItems); // Обновление состояния с отфильтрованными элементами
            }
        }
    }


    useEffect(() => {
        if (cards && cards==='first') {
            setTales(first)
        }
        if (cards && cards === 'second') {
            setTales(second)
        }
    }, [cards]);

    useEffect(() => {
        if (filters.includes(999)) {
            if (mode==='list') {
                if (cards && cards==='first') {
                    setTales(first)
                }
                if (cards && cards === 'second') {
                    setTales(second)
                }
            } else {
                //setTales(favorites.first)
            }
            setFilters([])
            return
        }
        if (mode==='list') {
            if (filters.length===0) {
                if (cards && cards==='first') {
                    setTales(first)
                }
                if (cards && cards === 'second') {
                    setTales(second)
                }
            } else {
                if (cards && cards==='first') {
                    setTales(first.filter((t) => filters.includes(t.category)))
                }
                if (cards && cards === 'second') {
                    setTales(second.filter((t) => filters.includes(t.category)))
                }

            }
        }
        if (mode==='favorites') {
            // console.log(favorites)
            // if (filters.length===0) {
            //     setItems(favorites.first)
            // } else {
            //     setItems(favorites.first.filter((t) => filters.includes(t.category)))
            // }
        }
        console.log(filters)
    }, [filters])

    useEffect(() => {
        console.log(mode)
        if (mode==='favorites') {
            loadFromFavorites()
        }
        if (mode==='list') {
            if (cards && cards==='first') {
                setTales(first)
            }
            if (cards && cards === 'second') {
                setTales(second)
            }
        }
    }, [mode]);

    return (
        <div className={`background`}>
            <div className='content'>
                <div className={`header ${styles.header}`}>
                    <div className={`header_block_border-wrap header_side ${styles.header_side}`}>
                        <div className='header_block'>
                            <img src="../Back.svg" alt="" onClick={goBack} style={{cursor: "pointer"}}/>
                        </div>
                    </div>
                    <div className='header_block_border-wrap'>
                        <div className={`header_block ${styles.flexcenter}`}>
                            <div className='brown-bar' onClick={()=>setMode('list')}>
                                <img src="../grid.svg" alt=""/>
                                <img src="../барысы да.svg" alt=""/>
                            </div>
                            <div className='orange-bar' onClick={()=>setMode('favorites')}>
                                <img src="../star.svg" alt=""/>
                                <img src="/САЙЛАНМАЛАР.svg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className='header_block_border-wrap'>
                        <div className={`header_block ${styles.header_block}`}>
                            <img src="../Back.svg" className={`header_button ${styles.header_button}`} alt="" onClick={goBack} style={{cursor: "pointer"}}/>
                            <img src="../татар теле.svg" alt=""/>
                        </div>
                    </div>
                </div>
                <div className={styles.content}>
                    {cards && cards==='first' && (
                        <div className='top-menu'>
                            {categories && categories.map((category) => (
                                <div key={category.id}
                                     className={`top-menu-item ${filters.includes(category.id) || (category.id === 999 && filters.length === 0) ? 'top-menu-item-active' : ''}`}
                                     onClick={() => toggleFilter(category.id)}>
                                    {category.name}
                                </div>
                            ))}
                        </div>
                    )}
                    <div className={styles.tables}>
                        {tales && tales.map((item, idx) => (
                            <Link key={'card' + idx} to={`/tamyr/tables/table/${idx}`} state={{slide: item, cards: cards}}>
                                <div className={styles.tables_block}>
                                    <div className={styles.tables_block_image}>
                                        <img src={item.minified} alt=""/>
                                    </div>
                                    <div className={styles.tables_block_text}>
                                        {item.text}
                                    </div>
                                </div>
                            </Link>
                        ))}
                        {mode === 'favorites' && (
                            <img src="/trashcan.svg" alt="" className={`trashcan`}/>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page;
export {};
