import React, {useEffect} from 'react';
import styles from './instruction.module.scss';
import {Link, useNavigate} from "react-router-dom";

const Page = () => {

    const router = useNavigate();

    useEffect(() => {
        document.title = "Инструкция"; // Устанавливаем заголовок
    }, []);

    const goBack = () => {
        router(-1)
    };

    return (
        <div className={`background`}>
            <div className={`content`}>
                <div className={`${styles.header}`}>
                    <div className={`header_block_border-wrap ${styles.back_button}`}>
                        <div className='header_block'>
                            <img src="Back.svg" alt="" onClick={goBack} style={{cursor: "pointer"}}/>
                        </div>
                    </div>
                    <div className='header_block_border-wrap'>
                        <div className={`header_block ${styles.header_block}`}>
                            <div className={`header_block ${styles.header_back}`}>
                                <img src="Back.svg" className={`header_button`} alt="" onClick={goBack} style={{cursor: "pointer"}}/>
                            </div>
                            «Тамырлар» инструкциясе
                        </div>
                    </div>
                </div>
                <div className={`${styles.content}`}>
                    <div className={`${styles.content_block}`}>
                        <div className={`${styles.screenshot}`}>
                            <img src='tamyr_screen.png' alt='screenshot'/>
                        </div>
                        <div className={`${styles.white_block}`}>
                            <h2>
                                Төп менюда сез үзегез теләгән предметны сайлый аласыз:
                            </h2>
                            <div className={styles.schedule}>
                                <p>
                                    -ТАТАР ТЕЛЕ
                                </p>
                                <p>
                                    -ТАТАР ӘДӘБИЯТЫ
                                </p>
                                <p>
                                    "ТЕСТЛАР" режимында белемнәрегезне тикшерә аласыз.
                                </p>
                            </div>
                        </div>
                        <div className={`${styles.green_block}`}>
                            <Link to='#'  state={{ cards: 'second' }}>
                                <div className='orange-button'>
                                    <img src="АЛГА.svg" alt=""/>
                                </div>
                            </Link>
                        </div>
                        <div className={`${styles.white_block}`}>
                            <h2>
                                Үзегезне кызыксындырган режимны төймәсенә басып сайлый аласыз
                            </h2>
                        </div>
                    </div>
                    <div className={`${styles.content_block}`}>
                        <div className={`${styles.screenshot}`}>
                            <img src="table_screen.png" alt=""/>
                            <div className={`${styles.screenshot_round} ${styles.screenshot_round_red1}`}>
                                1
                            </div>
                            <div className={`${styles.screenshot_round} ${styles.screenshot_round_green1}`}>
                                2
                            </div>
                            <div className={`${styles.screenshot_round} ${styles.screenshot_round_orange1}`}>
                                3
                            </div>
                        </div>
                        <div className={`${styles.white_block_info}`}>
                            <div className={`${styles.point}`}>
                                <div className={`${styles.white_block_round} ${styles.white_block_round_red}`}>
                                    1
                                </div>
                                <p>
                                    ТАТАР ТЕЛЕ бүлегендә, экранның өске өлешендәге үзегезне кызыксындырган темага басып, кирәкле материалларны фильтрлый һәм сайлый аласыз.
                                    Теләсә нинди категорияне сайлагыз, һәм кушымта сезнең тема буенча материалларны сайлап бирәчәк.
                                </p>
                            </div>
                            <div className={`${styles.point}`}>
                                <div className={`${styles.white_block_round} ${styles.white_block_round_green}`}>
                                    2
                                </div>
                                <p>
                                    "Артка" төймәсенә басып, төп менюга кире кайта аласыз.
                                </p>
                            </div>
                            <div className={`${styles.point}`}>
                                <div className={`${styles.white_block_round} ${styles.white_block_round_orange}`}>
                                    3
                                </div>
                                <p>
                                    Барлык материалларны төп менюга кайтарыр өчен, "БАРЫСЫ ДА" төймәсенә басыгыз. Сайлап алынган материалны "САЙЛАНМАЛАР" төймәсенә басып табарга мөмкин.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.content_block}`}>
                        <div className={`${styles.screenshot}`}>
                            <img src="/tables_screen.png" alt=""/>
                            <div className={`${styles.screenshot_round} ${styles.screenshot_round_red2}`}>
                                1
                            </div>
                            <div className={`${styles.screenshot_round} ${styles.screenshot_round_orange2}`}>
                                2
                            </div>
                            <div className={`${styles.screenshot_round} ${styles.screenshot_round_green2}`}>
                                3
                            </div>
                            <div className={`${styles.screenshot_round} ${styles.screenshot_round_lightred1}`}>
                                4
                            </div>
                            <div className={`${styles.screenshot_round} ${styles.screenshot_round_purple1}`}>
                                5
                            </div>
                        </div>
                        <div className={`${styles.white_block_info}`}>
                            <div className={`${styles.point}`}>
                                <div className={`${styles.white_block_round} ${styles.white_block_round_red}`}>
                                    1
                                </div>
                                <p>
                                    "Артка" төймәсенә басып, төп менюга кире кайта аласыз.
                                </p>
                            </div>
                            <div className={`${styles.point}`}>
                                <div className={`${styles.white_block_round} ${styles.white_block_round_orange}`}>
                                    2
                                </div>
                                <p>
                                    Бу плакатның материалларын тыңлар өчен, төймәләргә чиратлап басып барыгыз. Бу төймәгә баскач, кушымта аудиоязманы сөйләтә башлый. Аңа ике тапкыр баскач, кушымта сөйләтүне туктачак.
                                </p>
                            </div>
                            <div className={`${styles.point}`}>
                                <div className={`${styles.white_block_round} ${styles.white_block_round_green}`}>
                                    3
                                </div>
                                <p>
                                    Бу төймәләр сәхифәләрне алга/артка күчерә.
                                </p>
                            </div>
                            <div className={`${styles.point}`}>
                                <div className={`${styles.white_block_round} ${styles.white_block_round_lightred}`}>
                                    4
                                </div>
                                <p>
                                    "САКЛАУ" төймәсе белән плакатны сайланмаларга өстәп куярга була. Бу функция ярдәмендә үзегезгә кирәкле материалны сайлап ала аласыз.
                                    "АЛЫП ТАШЛАУ" төймәсе ярдәмендә кулланучы плакатны сайланмалардан алып ташлый ала.
                                </p>
                            </div>
                            <div className={`${styles.point}`}>
                                <div className={`${styles.white_block_round} ${styles.white_block_round_purple}`}>
                                    5
                                </div>
                                <p>
                                    Бу төймәләр сурәтләрне үзегезгә уңайлы үлчәмгә кадәр зурайтырга яки
                                    кечерәйтергә булыша.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.content_block}`}>
                        <div className={`${styles.screenshot}`}>
                            <img src="/test_screen.png" alt=""/>
                            <div className={`${styles.screenshot_round} ${styles.screenshot_round_red3}`}>
                                1
                            </div>
                            <div className={`${styles.screenshot_round} ${styles.screenshot_round_orange3}`}>
                                2
                            </div>
                        </div>
                        <div className={`${styles.white_block_info}`}>
                            <div className={`${styles.point}`}>
                                <div className={`${styles.white_block_round} ${styles.white_block_round_red}`}>
                                    1
                                </div>
                                <p>
                                    "АЛГА" төймәсенә басып үзегезгә кирәкле режимны сайлагыз.
                                </p>
                            </div>
                            <div className={`${styles.point}`}>
                                <div className={`${styles.white_block_round} ${styles.white_block_round_orange}`}>
                                    2
                                </div>
                                <p>
                                    Һәр режим астында сез үтелгән тестлар статистикасын күрә аласыз. Анда:
                                    - Үтелгән тестлар саны;
                                    - Дөрес җавапларның процентларда нисбәте күрсәтелгән
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.content_block}`}>
                        <div className={`${styles.screenshot}`}>
                            <img src="/testpage_screen.png" alt=""/>
                            <div className={`${styles.screenshot_round} ${styles.screenshot_round_red4}`}>
                                1
                            </div>
                            <div className={`${styles.screenshot_round} ${styles.screenshot_round_orange4}`}>
                                2
                            </div>
                        </div>
                        <div className={`${styles.white_block_info}`}>
                            <div className={`${styles.point}`}>
                                <div className={`${styles.white_block_round} ${styles.white_block_round_red}`}>
                                    1
                                </div>
                                <p>
                                    Сул баганада сезнең җавапларыгыз күрсәтелгән:
                                    – Кызыл түгәрәк – ялгыш җавап
                                    – Яшел түгәрәк – дөрес җавап
                                    - Кызгылт-сары түгәрәк  – хәзерге сорау
                                </p>
                            </div>
                            <div className={`${styles.point}`}>
                                <div className={`${styles.white_block_round} ${styles.white_block_round_orange}`}>
                                    2
                                </div>
                                <p>
                                    Тест соравына җавап бирү өчен, сорау өстындагы җавапларның берсенә басыгыз.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.content_block}`}>
                        <div className={`${styles.screenshot}`}>
                            <img src="/screen_resultpage.png" alt=""/>
                            <div className={`${styles.screenshot_round} ${styles.screenshot_round_red5}`}>
                                1
                            </div>
                            <div className={`${styles.screenshot_round} ${styles.screenshot_round_orange5}`}>
                                2
                            </div>
                        </div>
                        <div className={`${styles.white_block_info}`}>
                            <div className={`${styles.point}`}>
                                <div className={`${styles.white_block_round} ${styles.white_block_round_red}`}>
                                    1
                                </div>
                                <p>
                                    Тест ахырында сез нәтиҗәне белерсез. Яшел квадратта дөрес җаваплар саны, ә кызыл квадратта ялгыш җаваплар саны күрсәтелә.
                                </p>
                            </div>
                            <div className={`${styles.point}`}>
                                <div className={`${styles.white_block_round} ${styles.white_block_round_orange}`}>
                                    2
                                </div>
                                <p>
                                    Тестны тагын бер тапкыр үтәр өчен, "КАБАТЛАРГА" төймәсенә басыгыз.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page;
